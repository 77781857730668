import { Link } from "gatsby"
import * as React from "react"

export const Plans = () => (
  <div>
    <div className="container py-5">
      <h2 className="text-center">料金プラン</h2>
      <p>
        お悩みに応じてプランをご選択いただけます。主なプランは以下の内容です。
        <br />
        決済はクレジットカードをご利用いただけます。
      </p>
      <div className="row align-items-start gx-3 text-center">
        <div className="col-12 col-md-4">
          <h3 className="my-1 h5 fw-bold rounded balloonBox mb-3">
            単発でのアドバイス
          </h3>
          <img
            src="/images/plan_advice.png"
            alt="about"
            height={150}
            placeholder="none"
            className="mb-0"
          />
          <p className="mt-2 text-start">
            LINEやBOOTHのチャットで、疑問に思っていることについて相談できます。
          </p>
        </div>
        <div className="col-12 col-md-4">
          <h3 className="my-1 h5 fw-bold rounded balloonBox mb-3">
            デザインの改善提案
          </h3>
          <img
            src="/images/plan_recture.png"
            alt="design"
            height={150}
            placeholder="none"
            className="mb-0"
          />
          <p className="mt-2 text-start">
            制作中のデザインを見せていただき赤入れを行ったり、提案を行います。
          </p>
        </div>
        <div className="col-12 col-md-4">
          <h3 className="my-1 h5 fw-bold rounded balloonBox mb-3">
            アセット制作
          </h3>
          <img
            src="/images/plan_asset.png"
            alt="magazine"
            height={150}
            placeholder="none"
            className="mb-0"
          />
          <p className="mt-2 text-start">
            バナーやYouTubeのサムネ制作など、運用に必要となるアセットを制作いたします。
          </p>
        </div>
      </div>

      <div className="text-center">
        <Link
          to="/plans"
          className="btn btn-primary rounded-pill btn-fixed-width py-3"
        >
          詳しく見る
        </Link>
      </div>
    </div>
  </div>
)
