import { Link } from "gatsby"
import * as React from "react"

export const Advisor = () => (
  <div className="d-flex flex-column align-items-center py-5 container">
    <h3 className="mt-0 mb-4">相談相手</h3>
    <p> nao</p>
    <a
      href="https://naoi109.github.io/"
      className="btn btn-outline-primary rounded-pill btn-fixed-width py-3 mb-4"
      target="_blank"
      rel="noreferrer"
    >
      ポートフォリオサイト
    </a>

    <p>
      広告代理店でのWebデザイナー、ゲーム事業会社でのUI設計・フロントエンド実装経験を経て、現在はフリーランスとして、UXデザイン（利用者目線で価値提供を行うための情報整理を行う）やUIデザイン（利用者に価値を伝えるためのインターフェースをつくる）を専門としています。
    </p>
    <p>
      Webサービスのデザインを得意としていますが、アプリデザイン・ゲームUI、印刷物などの制作経験もあります。
    </p>
    <p>
      仕事をしながら自分でも同人誌をつくり、コミックシティなどのイベントに長らく個人参加をしています。
    </p>

    <Link
      to="/philosophy"
      className="btn btn-primary rounded-pill btn-fixed-width py-3 mb-5"
    >
      私について詳しく
    </Link>

    <p>
      私自身に興味を持ってくださったり、一緒にお仕事をしたい、もしくはなんらかの理由でコンタクトを取りたいと思ってくださる方は、お手数ですがこのサイトのお問い合わせもしくはポートフォリオサイトのお問い合わせよりご連絡いただけたら幸いです。
    </p>

    <Link
      to="/hello"
      className="btn btn-secondary text-dark btn-fixed-width rounded-pill py-3"
    >
      お問い合わせ
    </Link>
  </div>
)
