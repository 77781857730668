import { Link } from "gatsby"
import * as React from "react"

export const About = () => (
  <div className="text-center"
    data-sal="fade"
    data-sal-duration="400"
  >
    <div className="container py-5">
      <h2
      >トナリノとは？</h2>
      <h3 className="h5 fw-bold">
        「ちょっと誰かに見てもらえたら」<br />
        そんな気持ちにお応えします。
      </h3>
      <p>
        トナリノは「つくりたいものがある」けれど<br />
        「悩んでしまう」つくり手の方に向けて、<br />
        デザイナーが立ち上げたアドバイザリーサービスです。
        </p>
      <Link to="/about" className="btn btn-primary btn-fixed-width rounded-pill py-3">詳しく見る</Link>
    </div>
  </div>
)

