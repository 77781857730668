import React from "react"
import PosterSp from "../../../images/poster_sp.png"
import { Concept } from "./Concept"

export const SpKeyVisual = () => {
  return (
    <div className="keyVisualContentContainer">
      <img className="keyVisualContent keyVisualContentImg" src={PosterSp} alt="poster" />
      <Concept />
    </div>
  )
}
