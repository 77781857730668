import { Link } from "gatsby"
import * as React from "react"

export const Service = () => (
  <div>
    <div className="container py-5">
      <h2 className="text-center">できること</h2>
      <p>
        「やってはみたけどなにか違う気がする」「でもどこがおかしいのかわからない」「いっそプロにお願いしたほうがいいのかもしれない、けれどできれば自分でやりきりたい」…そんなお悩みを抱える方と一緒に最適解を考えます。
      </p>
      <div className="row align-items-center gx-3 text-center">
        <div className="col-12 col-md-4">
          <h3 className="my-1 h5 fw-bold rounded balloonBox mb-3">Webサイトについて</h3>
          <img
            src="/images/service_web.png"
            alt="about"
            height={150}
            placeholder="none"
            className="mb-0"
          />
          <p className="text-start mt-2">サイトの作り方、ツールの相談、崩れてしまったところのフォローなど</p>
        </div>
        <div className="col-12 col-md-4">
          <h3 className="my-1 h5 fw-bold rounded balloonBox mb-3">デザインについて</h3>
          <img
            src="/images/service_design.png"
            alt="design"
            height={150}
            placeholder="none"
            className="mb-0"
          />
          <p className="text-start mt-2">ツールの使い方、つくったデザインに合う印刷手法や紙の材質など</p>
        </div>
        <div className="col-12 col-md-4">
          <h3 className="my-1 h5 fw-bold rounded balloonBox mb-3">同人誌について</h3>
          <img
            src="/images/service_book.png"
            alt="magazine"
            height={150}
            placeholder="none"
            className="mb-0"
          />
          <p className="text-start mt-2">同人誌の表紙やおしながき、はじめて同人誌をつくる際のオススメの印刷所の紹介など</p>
        </div>
      </div>

      <div className="text-center">
        <Link to="/service" className="btn btn-primary rounded-pill btn-fixed-width py-3">
          詳しく見る
        </Link>
      </div>
    </div>

  </div>
)
