import React, { useEffect, useRef } from "react"
import { Concept } from "./Concept"

export const KeyVisual = ({ video, width, onCanPlay, startPlay }) => {
  const videoRef = useRef(null)

  const handlePlayable = () => {
    onCanPlay()
  }

  const getVideoDom = () => {
    return document.querySelector(".keyVisualVideoTag")
  }

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      const videoDom = getVideoDom()
      videoDom.addEventListener("canplay", () => {
        handlePlayable()
      })
    }
  }, [])

  useEffect(() => {
    const videoDom = getVideoDom()
    videoDom.play()
  }, [startPlay])

  useEffect(() => {
    const videoDom = getVideoDom()
    videoDom.style.width = `${width}px`
  }, [width])

  const createVideoTag = () => {
    return {
      __html: `
      <video
        muted
        loop
        playsInline
        class="keyVisualVideoTag"
      >
        <source src="${video.mp4}" type="video/mp4" />
        <source src="${video.webm}" type="video/webm" />
      </video>
    `,
    }
  }

  return (
    <div className="keyVisualContentContainer">
      <div
        ref={videoRef}
        className="keyVisualContent"
        dangerouslySetInnerHTML={createVideoTag()}
      />

      <Concept />
    </div>
  )
}
