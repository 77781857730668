import React, { useState, useRef, useEffect } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { KeyVisual, SpKeyVisual } from "../components/top/KeyVisual"
import { About } from "../components/top/About"
import { Service } from "../components/top/Service"
import { Plans } from "../components/top/Plans"
import { Advisor } from "../components/top/Advisor"
import PCVideoMP4 from "../images/firstview_pc.mp4"
import PCVideoWEBM from "../images/firstview_pc.webm"
import SPVideoMP4 from "../images/firstview_sp.mp4"
import SPVideoWEBM from "../images/firstview_sp.webm"
import sal from "sal.js"

const IndexPage = () => {
  const containerRef = useRef(null)
  const lastAnimationRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [video, setVideo] = useState(null)
  const [startPlay, setStartPlay] = useState(false)

  useEffect(() => {
    if (containerRef.current) {
      const windowWidth = containerRef.current.getBoundingClientRect().width + 4
      setWidth(windowWidth)
      const videoSrc =
        windowWidth > 640
          ? { mp4: PCVideoMP4, webm: PCVideoWEBM }
          : { mp4: SPVideoMP4, webm: SPVideoWEBM }
      setVideo(videoSrc)
    }
  }, [containerRef])

  const handleCanPlay = () => {
    setStartPlay(true)
  }

  useEffect(() => {
    if (lastAnimationRef.current && video) {
      sal()
    }
  }, [lastAnimationRef, video])

  return (
    <Layout>
      <Seo />
      <div ref={containerRef} className="topContent">
        <section id="key-visual" className="keyVisualContainer">
          {width > 640 ? (
            <KeyVisual
              width={width}
              video={video}
              onCanPlay={handleCanPlay}
              startPlay={startPlay}
            />
          ) : (
            <SpKeyVisual />
          )}
        </section>

        <section id="about" className="aboutContainer py-5">
          <About />
        </section>
        <section id="service" className="serviceContainer py-3">
          <Service />
        </section>
        <section id="plans" className="plansContainer py-3">
          <Plans />
        </section>
        <section id="advisor" className="advisorContainer py-3">
          <Advisor />
          <div
            ref={lastAnimationRef}
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease-out-back"
            data-sal-repeat
          ></div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
