import React, { useEffect, useRef } from "react"

export const Concept = () => {
  return (
    <div className="titleContainer d-flex justify-content-start align-items-center">
      <div className="titleContent">
        <h1 className="fs-1 mb-2" data-sal="slide-up" data-sal-duration="400">
          <strong>自分の手でつくりたい</strong><br />
          そう思うあなたを<br />
          サポートします
        </h1>
        <p
          className="fw-bold mb-3"
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-delay="300"
        >
          トナリノはものづくりのなかで<br />
          気になることを相談できるサービスです
        </p>
        <p
          className="border border-3 border-primary p-1 mx-3 mx-md-0 py-md-2 px-md-3 mb-0 small bg-white"
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="300"
        >
          2023/11 現在受付を停止しております。<br />
          詳しくは<a href="https://note.com/tonarino_jp/n/n577105fb8ee6" target="_blank" rel="nofollow">受付状況のご案内</a>をご確認ください。<br />
          再開は来年の夏ごろを予定しています。
        </p>
      </div>
    </div>
  )
}
